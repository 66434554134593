import React, { ReactElement } from 'react';
import { GiCampingTent, GiToolbox } from 'react-icons/gi';
import { MdPayments, MdPermMedia } from 'react-icons/md';
import { SiChatbot } from 'react-icons/si';
import { AiOutlineAreaChart, AiOutlineTag } from 'react-icons/ai';
import { RiQuestionAnswerFill } from 'react-icons/ri';
import {
  FaBuilding,
  FaUserAlt,
  FaCalendar,
  FaTags,
  FaFileInvoiceDollar,
} from 'react-icons/fa';
import { BiNews } from 'react-icons/bi';

type ChildrenType = {
  label: string;
  key: string;
  icon: ReactElement;
};

export type NavigationProps = {
  label: string;
  key: string;
  icon: ReactElement;
  children?: ChildrenType[];
};

const Navigation: NavigationProps[] = [
  {
    label: 'Dashboard',
    key: 'dashboard',
    icon: React.createElement(AiOutlineAreaChart, null, null),
  },
  {
    label: 'News',
    key: 'news',
    icon: React.createElement(BiNews, null, null),
  },
  {
    label: 'Companies',
    key: 'companies',
    icon: React.createElement(FaBuilding, null, null),
  },
  {
    label: 'Camping',
    key: 'camping',
    icon: React.createElement(GiCampingTent, null, null),
  },
  {
    label: 'Users',
    key: 'users',
    icon: React.createElement(FaUserAlt, null, null),
  },
  {
    label: 'Devices',
    key: 'devices',
    icon: React.createElement(GiToolbox, null, null),
  },
  {
    label: 'Media',
    key: 'medias',
    icon: React.createElement(MdPermMedia, null, null),
  },
  {
    label: 'Tag',
    key: 'alltags',
    icon: React.createElement(FaTags, null, null),
    children: [
      {
        label: 'Media-Tag',
        key: 'tags',
        icon: React.createElement(AiOutlineTag),
      },
      {
        label: 'RP-Tag',
        key: 'rp-tags',
        icon: React.createElement(AiOutlineTag),
      },
    ],
  },

  {
    label: 'Invoice',
    key: 'invoices',
    icon: React.createElement(FaFileInvoiceDollar, null, null),
    // children: {
    //   label: 'Booking',
    //   key: 'bookings',
    //   icon: React.createElement(FaTags, null, null),
    // },
  },
  {
    label: 'Booking',
    key: 'bookings',
    icon: React.createElement(FaCalendar, null, null),
  },
  {
    label: 'Payment',
    key: 'payments',
    icon: React.createElement(MdPayments, null, null),
  },
  {
    label: 'Chatbot',
    key: 'chatbot',
    icon: React.createElement(SiChatbot, null, null),
  },
  {
    label: 'Q&A',
    key: 'q&a',
    icon: React.createElement(RiQuestionAnswerFill, null, null),
  },
];
export default Navigation;
