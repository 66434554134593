import LeftPanel from '@components/Header/components/LeftPanel';
import RightPanel from '@components/Header/components/RightPanel';
import { Col, Row } from 'antd';
import './style.less';

const Header = () => {
  return (
    <Row justify="space-between" align="middle">
      <Col>
        <LeftPanel />
      </Col>
      <Col>
        <RightPanel />
      </Col>
    </Row>
  );
};

export default Header;
