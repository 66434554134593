import { createSlice } from '@reduxjs/toolkit';

type IdentificationProps = {
  isLoggedIn?: boolean;
};
const initialState: IdentificationProps = {
  isLoggedIn: false,
};
const IdentificationSlice = createSlice({
  name: 'Identification',
  initialState,
  reducers: {
    setIdentification(state) {
      state.isLoggedIn = true;
    },
    clearIdentification(state) {
      state.isLoggedIn = false;
    },
  },
});
export const IdentifiAction = IdentificationSlice.actions;
export default IdentificationSlice.reducer;
