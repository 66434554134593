import { DataDeviceType } from '@apis/receiveDataType';
import { createSlice } from '@reduxjs/toolkit';

type InitialProps = {
  dataProperty: DataDeviceType[];
};
const initialState: InitialProps = {
  dataProperty: [],
};
const RentalPropertySlice = createSlice({
  name: 'rental-property',
  initialState,
  reducers: {
    setDataProperty(state, action) {
      state.dataProperty = action.payload;
    },
  },
});
export const RentalPropertyAction = RentalPropertySlice.actions;
export default RentalPropertySlice.reducer;
