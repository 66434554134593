import { Row, Col } from 'antd';
import React from 'react';

const Footer: React.FC = () => {
  return (
    <Row justify="center" align="middle">
      <Col style={{ display: 'flex', justifyContent: 'center' }} span={24}>
        <h3>Greenlife Camping</h3>
      </Col>
    </Row>
  );
};
export default Footer;
