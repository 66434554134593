import ROUTE_PATH from '@constants/routes';
import { FC, ReactElement } from 'react';
import { RouteProps, Navigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

export const NavigateToLogin = ({ fallback }: { fallback?: string }) => {
  return <Navigate to={ROUTE_PATH.SIGN_IN} state={{ fallback }} />;
};

NavigateToLogin.defaultProps = {
  fallback: undefined,
};

const PrivateRoute: FC<RouteProps> = ({ path, children }) => {
  const authorization = useAppSelector((state) => state.authorization);

  if (authorization == null) return <NavigateToLogin fallback={path} />;
  return children as ReactElement;
};

export default PrivateRoute;
