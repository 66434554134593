import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const SuspenseFallback = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: '5rem' }} spin />;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        marginTop: '2rem',
      }}
    >
      <Spin indicator={antIcon} />
    </div>
  );
};

export default SuspenseFallback;
