import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authorizationReducer from './slices/authorization/authorizationSlice';
import IdentificationReducer from './slices/identification/identification';
import CompanyReducer from './slices/company/companySlice';
import RentalPropertyReducer from './slices/rental-property/rental-property-slice';

export const store = configureStore({
  reducer: {
    authorization: authorizationReducer,
    identification: IdentificationReducer,
    company: CompanyReducer,
    rentalProperty: RentalPropertyReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
