import { DataUserType } from '@apis/receiveDataType';
import ROUTE_PATH from '@constants/routes';
import { Avatar, Button, Col, Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type PairedUserProps = {
  user: DataUserType;
  onClose: () => void;
};

const PairedUser: React.FC<PairedUserProps> = ({ user, onClose }) => {
  const { t } = useTranslation('Common');

  return (
    <>
      <Typography.Title level={3}>
        {t('PairingClient.Modal.Result.User.Title')}
      </Typography.Title>
      <Row justify="space-around" align="middle" gutter={[20, 20]}>
        <Col>
          <Avatar size={75} shape="square" alt={user.firstName}>
            {user.firstName}
          </Avatar>
        </Col>
        <Col>
          <Space direction="vertical" className="full-width">
            <Row justify="space-between" gutter={[20, 20]}>
              <Col>
                <Typography.Text>
                  {t('PairingClient.Modal.Result.User.Name')}:
                </Typography.Text>
              </Col>
              <Col>
                <Space>
                  <Typography.Text strong>
                    {`${user.firstName} ${user.lastName}`}
                  </Typography.Text>
                  <Typography.Text>
                    {`${user.firstNameHiragana} ${user.lastNameHiragana}`}
                  </Typography.Text>
                </Space>
              </Col>
            </Row>
            <Row justify="space-between" gutter={[20, 20]}>
              <Col>
                <Typography.Text>
                  {t('PairingClient.Modal.Result.User.DoB')}:
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text>{user.dob}</Typography.Text>
              </Col>
            </Row>
            <Link
              to={ROUTE_PATH.USERDETAIL.replace(':userId', user.id)}
              onClick={onClose}
            >
              <Button type="link" className="full-width">
                {t('PairingClient.Modal.Result.User.Detail')}
              </Button>
            </Link>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default PairedUser;
