import ROUTE_PATH from '@constants/routes';
import { FC, ReactElement } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';

export const NavigateToNotFound = () => {
  return <Navigate to={ROUTE_PATH.NOT_FOUND} />;
};

const PublicRoute: FC<RouteProps> = ({ children }) => {
  return children as ReactElement;
};

export default PublicRoute;
