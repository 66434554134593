// Path type definitions
export enum UserPath {
  Me = '/users/me',
  getUsers = '/users',
  createUser = 'users/create-user',
  updateUser = '/users',
  deleteuser = 'users/:id',
}
export enum CompanyPath {
  All = '/companies',
}
export enum DevicePath {
  All = '/:workspace/rental-properties',
}
export enum QuestionAndAnswerPath {
  All = '/qnas',
}
export enum MediasPath {
  All = '/:workspace/medias',
}
export enum BookingPath {
  All = '/:workspaceId/bookings',
  Validate = '/:workspaceId/bookings/validate',
}
export enum MediaTagPath {
  All = '/:workspace/media-tags',
}
export enum RPTag {
  DEFAULT = '/rp-tags',
}
export enum InvoicePath {
  All = '/:workspace/invoices',
}
export enum PaymentPath {
  All = '/:workspace/payments',
}
export enum CampingMapPath {
  DEFAULT = '/map',
}
export enum ChatbotPath {
  DEFAULT = '/chatbot-config',
  BACKUP = '/chatbot-config/backup',
  ROLLBACK = 'chatbot-config/rollback',
}
export enum AnalyticsPath {
  DAILY = 'analytics/daily',
  MONTHLY = 'analytics/monthly',
}
export enum WeatherPath {
  DEFAULT = '/weather',
}
export enum NewsPath {
  DEFAULT = '/news',
}
