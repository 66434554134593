import { generatePairCode, getPairResult } from '@apis/pairing/pairing';
import { IPairedCode } from '@apis/pairing/pairing.interface';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const usePairingClient = ({ enable }: { enable: boolean }) => {
  const { t } = useTranslation('Error');
  const [code, setCode] = useState<string | undefined>();
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [result, setResult] = useState<IPairedCode>();
  const done = useMemo(() => {
    if (!code) return true;
    return result?.pairedTimestamp != null;
  }, [code, result?.pairedTimestamp]);

  const generateCode = useCallback(async () => {
    setError(undefined);
    setLoading(true);
    try {
      const response = await generatePairCode();
      if (response.status !== 200 || !response.data.pairCode)
        throw new Error(t('Error:GeneratePairingCodeFailed') as string);
      setCode(response.data.pairCode);
      setResult(undefined);
    } catch (err) {
      setError(err as Error);
    }
    setLoading(false);
  }, []);

  const fetchResult = useCallback(async () => {
    if (!code || !enable) return;
    try {
      const response = await getPairResult(code);
      if (response.status !== 200)
        throw new Error(t('Error:GetPairingResultFailed') as string);
      if (response.data.pairedTimestamp == null) return;

      setResult(response.data);
    } catch (err) {
      //
    }
  }, [code, enable]);

  const deactivate = useCallback(() => {
    setCode(undefined);
  }, []);

  useEffect(() => {
    generateCode();
  }, [generateCode]);

  useEffect(() => {
    if (!done) {
      const loop = setInterval(fetchResult, 5000);
      return () => {
        clearInterval(loop);
      };
    }
    return () => {};
  }, [done, fetchResult]);

  return {
    code,
    error,
    loading,
    result,
    generateCode,
    deactivate,
  };
};

export default usePairingClient;
