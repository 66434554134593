import { SUPPORTED_LOCALES } from '@constants/locales';
import { Dropdown, Space, Typography } from 'antd';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ukFlag from '@assets/images/uk_flag.png';
import jpFlag from '@assets/images/jp_flag.png';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const items = useMemo(
    () =>
      SUPPORTED_LOCALES.map(({ value, label }) => ({
        key: value,
        label: (
          <div className="flag-container">
            <img
              src={label === 'ENG' ? ukFlag : jpFlag}
              alt="flag"
              className="flag-img"
            />
            {label}
          </div>
        ),
      })),
    [],
  );
  const currentLang = useMemo(
    () => SUPPORTED_LOCALES.find(({ value }) => value === i18n.language),
    [i18n.language],
  );

  useEffect(() => {
    if (!currentLang) i18n.changeLanguage(SUPPORTED_LOCALES[0].value);
  }, [currentLang, i18n]);

  const handleLangChange = useCallback(
    ({ key }: { key: string }) => {
      i18n.changeLanguage(key);
    },
    [i18n],
  );

  return (
    <Dropdown menu={{ items, onClick: handleLangChange }} arrow>
      <Space>
        <Typography.Text>
          {currentLang && (
            <img
              src={currentLang.label === 'ENG' ? ukFlag : jpFlag}
              alt="select-lang"
              className="flag-img"
            />
          )}
        </Typography.Text>
        {/* <DownOutlined /> */}
      </Space>
    </Dropdown>
  );
};

export default LanguageSelector;
