import { ReloadOutlined } from '@ant-design/icons';
import PairingResult from '@components/PairingClient/components/PairingResult';
import usePairingClient from '@hooks/PairingClient';
import { Button, Modal, Space, Spin, Typography } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

type PairingModalProps = {
  open: boolean;
  onClose: () => void;
};

const PairingModal: React.FC<PairingModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation('Common');

  const { code, loading, error, result, generateCode, deactivate } =
    usePairingClient({ enable: open });

  useEffect(() => {
    if (!open) deactivate();
  }, []);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      forceRender={false}
      destroyOnClose
      footer={null}
      title={
        <Typography.Text strong>
          {t('PairingClient.Modal.Title')}
        </Typography.Text>
      }
    >
      <Space direction="vertical" className="full-width items-center">
        <Button
          onClick={generateCode}
          disabled={loading}
          className="full-width"
        >
          <ReloadOutlined />
        </Button>

        {result ? (
          <PairingResult result={result} onClose={onClose} />
        ) : (
          <Spin spinning={loading}>
            {error ? (
              <Typography.Text type="danger" strong>
                {error.message}
              </Typography.Text>
            ) : (
              code && (
                <Space direction="vertical" className="full-width item-center">
                  <QRCode value={code} />

                  <Typography.Title level={3} copyable>
                    {code}
                  </Typography.Title>
                </Space>
              )
            )}
          </Spin>
        )}
      </Space>
    </Modal>
  );
};

export default PairingModal;
