const ROUTE_PATH = {
  HOME: '/',
  SIGN_IN: '/sign-in',
  USER_PROFILE: '/profile',
  DASHBOARD: '/dashboard',
  ALLUSERS: '/users',
  USERDETAIL: '/users/:userId',
  SELECT_COMPANY: '/select-workspace',
  COMPANY: '/companies',
  COMPANY_DETAIL: '/companies/:id',
  DEVICE: '/devices',
  DEVICE_DETAIL: '/devices/:id',
  MEDIAS: '/medias',
  MEDIA_DETAIL: '/medias/:id',
  TAGS: '/tags',
  BOOKING: '/bookings',
  BOOKING_DETAIL: '/bookings/:id',
  INVOICES: '/invoices',
  INVOICES_DETAIL: '/invoices/:id',
  PAYMENT: '/payments',
  PAYMENT_DETAIL: '/payments/:id',
  QUESANS: '/q&a',
  NOT_FOUND: '404',
  CAMPING: '/camping',
  CHATBOT: '/chatbot',
  NEWS: '/news',
  RPTag: '/rp-tags',
  OTHER: '*',
};

export default ROUTE_PATH;
