import apiInstance from '@apis/index';

export enum AuthPath {
  Login = '/auth/login',
  Logout = '/auth/logout',
}

export const login = async (email: string, password: string) => {
  return apiInstance.post(AuthPath.Login, {
    email,
    password,
  });
};

export const logout = async () => {
  return apiInstance.get(AuthPath.Logout);
};
