import LanguageSelector from '@components/Header/components/LanguageSelector';
import UserHolder from '@components/Header/components/UserHolder';
import PairingClient from '@components/PairingClient';
import { Space } from 'antd';

const RightPanel = () => {
  return (
    <Space>
      <UserHolder />
      <LanguageSelector />
      <PairingClient />
    </Space>
  );
};

export default RightPanel;
