import { DataInvoiceType } from '@apis/receiveDataType';

import ROUTE_PATH from '@constants/routes';

import { DEFAULT_TIME_FORMAT } from '@constants/time';

import { Button, Col, Row, Space, Typography } from 'antd';

import moment from 'moment';

import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

type PairedInvoiceProps = {
  invoice: DataInvoiceType;

  onClose: () => void;
};

const PairedInvoice: React.FC<PairedInvoiceProps> = ({ invoice, onClose }) => {
  const { t } = useTranslation('Common');

  return (
    <>
      <Typography.Title level={3}>
        {t('PairingClient.Modal.Result.Invoice.Title')}
      </Typography.Title>

      <Space direction="vertical" className="full-width">
        <Row justify="space-between">
          <Col>
            <Typography.Text>
              {t('PairingClient.Modal.Result.Invoice.Id')}:
            </Typography.Text>
          </Col>

          <Col>
            <Typography.Text>{invoice.id}</Typography.Text>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col>
            <Typography.Text>
              {t('PairingClient.Modal.Result.Invoice.CreatedAt')}:
            </Typography.Text>
          </Col>

          <Col>
            <Typography.Text>
              {moment(invoice.createdAt).format(DEFAULT_TIME_FORMAT)}
            </Typography.Text>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col>
            <Typography.Text>
              {t('PairingClient.Modal.Result.Invoice.People')}:
            </Typography.Text>
          </Col>

          <Col>
            <Space size={10}>
              <Typography.Text>
                <Space size={3}>
                  {invoice.adults}

                  {t('PairingClient.Modal.Result.Invoice.Adult')}
                </Space>
              </Typography.Text>

              <Typography.Text>
                <Space size={3}>
                  {invoice.children}

                  {t('PairingClient.Modal.Result.Invoice.Children')}
                </Space>
              </Typography.Text>
            </Space>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col>
            <Typography.Text>
              {t('PairingClient.Modal.Result.Invoice.State')}:
            </Typography.Text>
          </Col>

          <Col>
            <Typography.Text>{invoice.state}</Typography.Text>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col>
            <Typography.Text>
              {t('PairingClient.Modal.Result.Invoice.Note')}:
            </Typography.Text>
          </Col>

          <Col>
            <Typography.Text>{invoice.note}</Typography.Text>
          </Col>
        </Row>

        <Link
          to={ROUTE_PATH.INVOICES_DETAIL.replace(':id', invoice.id)}
          onClick={onClose}
        >
          <Button type="link" className="full-width">
            {t('PairingClient.Modal.Result.Invoice.Detail')}
          </Button>
        </Link>
      </Space>
    </>
  );
};

export default PairedInvoice;
