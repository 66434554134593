import React, { lazy, ReactNode } from 'react';
import pageLoader from '@utils/pageLoader';
import ROUTE_PATH from '@constants/routes';
import { RouteProps } from 'react-router-dom';
import { NavigateToNotFound } from '@templates/PolicyRoute/PublicRoute';
import ErrorBoundary from '@components/ErrorBoundary';

const SignInPage = lazy(() => import('@pages/SignIn'));
const ExplorePage = lazy(pageLoader(() => import('@pages/Explore')));
const HomePage = lazy(pageLoader(() => import('@pages/Homepage')));
const NotFoundPage = lazy(pageLoader(() => import('@pages/NotFound')));
const DashBoardPage = lazy(pageLoader(() => import('@pages/Dashboard')));
const AllUsers = lazy(pageLoader(() => import('@pages/User')));
const Devices = lazy(pageLoader(() => import('@pages/Device')));
const DeviceDetail = lazy(
  pageLoader(() => import('@pages/Device/DeviceDetail')),
);
const SelectCompany = lazy(
  pageLoader(() => import('@pages/Company/SelectCompany')),
);
const CompanyPage = lazy(pageLoader(() => import('@pages/Company')));
const CompanyDetail = lazy(
  pageLoader(() => import('@pages/Company/CompanyDetail')),
);
const QuestionAndAnswerPage = lazy(pageLoader(() => import('@pages/Q&A')));
const UserDetail = lazy(
  pageLoader(() => import('@pages/User/components/UserDetail')),
);
const UserProfile = lazy(
  pageLoader(() => import('@pages/User/components/UserProfile')),
);
const MediaPage = lazy(pageLoader(() => import('@pages/Media')));
const MediaDetail = lazy(pageLoader(() => import('@pages/Media/MediaDetail')));
const TagPage = lazy(pageLoader(() => import('@pages/Tag')));
const BookingPage = lazy(pageLoader(() => import('@pages/Booking')));
const BookingDetail = lazy(
  pageLoader(() => import('@pages/Booking/BookingDetail')),
);
const InvoicePage = lazy(pageLoader(() => import('@pages/Invoice')));
const InvoiceDetail = lazy(
  pageLoader(() => import('@pages/Invoice/InvoiceDetail')),
);
const PaymentPage = lazy(pageLoader(() => import('@pages/Payment')));
const PaymentDetail = lazy(
  pageLoader(() => import('@pages/Payment/PaymentDetail')),
);
const ChatbotPage = lazy(pageLoader(() => import('@pages/Chatbot')));
const NewsPage = lazy(pageLoader(() => import('@pages/News')));
const RPTagsPage = lazy(pageLoader(() => import('@pages/RPTags')));

type RouterExtraProps = {
  isPublic: boolean;
  suspenseFallback?: ReactNode;
};

type CustomRouterProps = RouteProps & RouterExtraProps;
// const child = React.createElement('h3', null, 'This is children');
const ROUTER: CustomRouterProps[] = [
  {
    isPublic: true,
    path: ROUTE_PATH.HOME,
    element: React.createElement(HomePage, null, null),
  },
  {
    isPublic: true,
    path: ROUTE_PATH.SIGN_IN,
    element: React.createElement(SignInPage, null, null),
  },
  {
    isPublic: false,
    path: ROUTE_PATH.SELECT_COMPANY,
    element: React.createElement(SelectCompany, null, null),
  },
  {
    isPublic: true,
    path: ROUTE_PATH.NOT_FOUND,
    element: React.createElement(NotFoundPage, null, null),
  },
  {
    isPublic: false,
    path: ROUTE_PATH.DASHBOARD,
    element: React.createElement(DashBoardPage),
  },
  // Route for user
  {
    isPublic: false,
    path: ROUTE_PATH.ALLUSERS,
    element: React.createElement(AllUsers, null, null),
  },
  {
    isPublic: false,
    path: ROUTE_PATH.USERDETAIL,
    element: React.createElement(UserDetail, null, null),
  },
  {
    isPublic: false,
    path: ROUTE_PATH.USER_PROFILE,
    element: React.createElement(UserProfile, null, null),
  },
  // Route for device
  {
    isPublic: false,
    path: ROUTE_PATH.DEVICE,
    element: React.createElement(Devices, null, null),
  },
  {
    isPublic: false,
    path: ROUTE_PATH.DEVICE_DETAIL,
    element: React.createElement(DeviceDetail, null, null),
  },
  // Route for company
  {
    isPublic: false,
    path: ROUTE_PATH.COMPANY,
    element: React.createElement(CompanyPage, null, null),
  },
  {
    isPublic: false,
    path: ROUTE_PATH.COMPANY_DETAIL,
    element: React.createElement(CompanyDetail, null, null),
  },
  // Route for q&a
  {
    isPublic: false,
    path: ROUTE_PATH.QUESANS,
    element: React.createElement(QuestionAndAnswerPage, null, null),
  },
  // Media and Tag
  {
    isPublic: false,
    path: ROUTE_PATH.MEDIAS,
    element: React.createElement(
      ErrorBoundary,
      null,
      React.createElement(MediaPage),
    ),
  },
  {
    isPublic: false,
    path: ROUTE_PATH.MEDIA_DETAIL,
    element: React.createElement(MediaDetail, null, null),
  },
  {
    isPublic: false,
    path: ROUTE_PATH.TAGS,
    element: React.createElement(TagPage, null, null),
  },
  // Booking
  {
    isPublic: false,
    path: ROUTE_PATH.BOOKING,
    element: React.createElement(BookingPage, null, null),
  },
  {
    isPublic: false,
    path: ROUTE_PATH.BOOKING_DETAIL,
    element: React.createElement(BookingDetail, null, null),
  },
  // Invoices
  {
    isPublic: false,
    path: ROUTE_PATH.INVOICES,
    element: React.createElement(InvoicePage, null, null),
  },
  {
    isPublic: false,
    path: ROUTE_PATH.INVOICES_DETAIL,
    element: React.createElement(InvoiceDetail, null, null),
  },
  // Payment
  {
    isPublic: false,
    path: ROUTE_PATH.PAYMENT,
    element: React.createElement(PaymentPage, null, null),
  },
  {
    isPublic: false,
    path: ROUTE_PATH.PAYMENT_DETAIL,
    element: React.createElement(PaymentDetail, null, null),
  },
  // Camping map
  {
    isPublic: false,
    path: ROUTE_PATH.CAMPING,
    element: React.createElement(ExplorePage, null, null),
  },
  // Chatbot
  {
    isPublic: false,
    path: ROUTE_PATH.CHATBOT,
    element: React.createElement(ChatbotPage),
  },
  // News
  {
    isPublic: false,
    path: ROUTE_PATH.NEWS,
    element: React.createElement(
      ErrorBoundary,
      null,
      React.createElement(NewsPage),
    ),
  },
  // RP Tag
  {
    isPublic: false,
    path: ROUTE_PATH.RPTag,
    element: React.createElement(RPTagsPage),
  },

  // Fallback router
  {
    isPublic: true,
    path: ROUTE_PATH.OTHER,
    element: React.createElement(NavigateToNotFound, null, null),
  },
];

export default ROUTER;
