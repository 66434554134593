import { ReactComponent as Logo } from '@assets/icon_camp-tent.svg';

const LeftPanel = () => {
  return (
    <div className="logo-container">
      <Logo className="logo" />
    </div>
  );
};

export default LeftPanel;
