import { DataUserType } from '@apis/receiveDataType';
import { createSlice } from '@reduxjs/toolkit';

export type CompanyProps = {
  id: string;
  name: string;
  nameKana: string;
  address: string;
  phoneNumber: string;
  email: string;
  morderators: DataUserType[];
};
type InitialProps = {
  dataCompany: CompanyProps[];
  workspaceId?: string;
};
const initialState: InitialProps = {
  dataCompany: [],
  workspaceId: undefined,
};
const CompanySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setDataCompany(state, action) {
      state.dataCompany = action.payload;
    },
    setWorkspace(state, action) {
      state.workspaceId = action.payload;
    },
  },
});
export const CompanyAction = CompanySlice.actions;
export default CompanySlice.reducer;
