import { getAllCompany, whoAmI } from '@apis/admin';
import ErrorBoundary from '@components/ErrorBoundary';
import Footer from '@components/Footer';
import Header from '@components/Header';
import MenuSider from '@components/Layout/MenuAside';
import { SupportedLocale } from '@constants/locales';
import ROUTER from '@constants/routers';
import '@helpers/i18next';
import { setUserData } from '@slices/authorization/authorizationSlice';
import { CompanyAction } from '@slices/company/companySlice';
import { IdentifiAction } from '@slices/identification/identification';
import PolicyRoute from '@templates/PolicyRoute';
import SuspenseFallback from '@templates/SuspenseFallback';
import { getAccessToken } from '@utils/auth';
import { ConfigProvider, Layout } from 'antd';
import enGB from 'antd/lib/locale/en_GB';
import jaJP from 'antd/lib/locale/ja_JP';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import './App.less';

const App = () => {
  const { i18n } = useTranslation('Common');
  const locale = useMemo(() => {
    switch (i18n.language) {
      case SupportedLocale['ja-JP']:
        return jaJP;
      default:
        return enGB;
    }
  }, [i18n.language]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector((state) => state.identification.isLoggedIn);
  const [reAuth, setReAuth] = useState<boolean>(true);
  const state = useLocation();

  useEffect(() => {
    const accessToken = getAccessToken();
    if (accessToken) {
      const getAuth = async () => {
        try {
          const res = await whoAmI();
          dispatch(setUserData(res.data));
          const resCompany = await getAllCompany();
          dispatch(CompanyAction.setDataCompany(resCompany?.data?.data));
          dispatch(IdentifiAction.clearIdentification());
          setReAuth(false);
          navigate('/select-workspace');
        } catch (err) {
          console.log(err);
          navigate('/sign-in');
        }
      };
      getAuth();
    } else {
      setReAuth(false);
      navigate('/sign-in', { state });
    }
  }, []);

  return (
    <ConfigProvider prefixCls="glc" locale={locale}>
      <ErrorBoundary>
        <Layout id="layout-root">
          <Layout.Header id="layout-header">
            <Header />
          </Layout.Header>
          {reAuth ? (
            <SuspenseFallback />
          ) : (
            <Layout id="layout-main-content">
              {isLoggedIn && <MenuSider />}
              <Layout.Content id="layout-content">
                <Routes>
                  {ROUTER.map(
                    ({ path, isPublic, element, suspenseFallback }) => (
                      <Route
                        path={path}
                        key={path}
                        element={
                          <PolicyRoute
                            {...{ path, isPublic, suspenseFallback }}
                          >
                            {element}
                          </PolicyRoute>
                        }
                      />
                    ),
                  )}
                </Routes>
              </Layout.Content>
            </Layout>
          )}

          <Layout.Footer id="layout-footer">
            <Footer />
          </Layout.Footer>
        </Layout>
      </ErrorBoundary>
    </ConfigProvider>
  );
};

export default App;
