/* eslint-disable */
import { Result } from 'antd';
import { Component, ErrorInfo, ReactNode } from 'react';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

interface Props {
  children?: ReactNode;
  t: TFunction;
}

interface State {
  hasError: boolean;
  error?: Error | null;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
  };

  public static getDerivedStateFromError(err: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: err };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error )):', error, errorInfo);
  }

  public render() {
    const { hasError, error } = this.state;
    const { t } = this.props;
    if (hasError) {
      return (
        <>
          <Result
            title={`${t('ErrorBoundary.Title')} ${t('ErrorBoundary.Error')}: "${
              error?.message
            }"`}
            status="error"
          />
          <div
            className="suggesst"
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <h1>{t('ErrorBoundary.Suggest')}</h1>
          </div>
        </>
      );
    }
    return this.props.children;
  }
}

export default withTranslation('Error')(ErrorBoundary);
