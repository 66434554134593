import { Button, Dropdown, Space, Typography } from 'antd';
import type { MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { clearAuthorizationToken } from '@utils/auth';
import { clearUserData } from '@slices/authorization/authorizationSlice';
import { logout } from '@apis/auth';
import { IdentifiAction } from '@slices/identification/identification';
import { DownOutlined, LogoutOutlined } from '@ant-design/icons';
import BackToSelectCompany from '../BackToSelectCompany';

const UserHolder = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const authorization = useAppSelector((state) => state.authorization);
  const logoutHandler = async () => {
    await logout();
    clearAuthorizationToken();
    dispatch(clearUserData());
    dispatch(IdentifiAction.clearIdentification());
    navigate('/sign-in');
  };
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link to="/profile">
          <button type="button" className="btn btn-user-holder">
            {t('Profile')}
          </button>
        </Link>
      ),
    },
    {
      key: 'select-workspace',
      label: <BackToSelectCompany />,
    },
    {
      key: '2',
      label: (
        <button
          type="button"
          onClick={logoutHandler}
          className="btn btn-user-holder"
        >
          <LogoutOutlined /> {t('Logout')}
        </button>
      ),
    },
  ];
  if (authorization) {
    return (
      <Dropdown menu={{ items }} arrow>
        <Space>
          <Typography.Text strong style={{ color: '#fff' }}>{`${
            authorization.firstName ? authorization.firstName : ''
          } ${
            authorization.lastName ? authorization.lastName : ''
          }`}</Typography.Text>
          <DownOutlined />
        </Space>
      </Dropdown>
    );
  }

  return <Button onClick={() => navigate('/sign-in')}>{t('Login')}</Button>;
};

export default UserHolder;
