import apiInstance from '@apis/index';
import {
  UserPath,
  CompanyPath,
  DevicePath,
  QuestionAndAnswerPath,
  MediasPath,
  MediaTagPath,
  BookingPath,
  InvoicePath,
  PaymentPath,
  CampingMapPath,
  ChatbotPath,
  AnalyticsPath,
  NewsPath,
  RPTag,
} from '@constants/path';
import { MappingCampRP } from '@hooks/CampingMap/useMappingCampRP';

// User
export const whoAmI = async () => {
  return apiInstance.get(UserPath.Me);
};
export const getAllUsers = async (params?: API.GetAllUserParamsProps) => {
  return apiInstance.get(UserPath.getUsers, { params });
};
export const getUser = async (id: string) => {
  return apiInstance.get(`${UserPath.getUsers}/${id}`);
};
export const adminCreateuser = async (body: API.CreateUserProps) => {
  return apiInstance.post(UserPath.createUser, body);
};
export const adminUpdateUser = async (
  id: string,
  body: API.UpdateUserProfileProps,
) => {
  return apiInstance.patch(`${UserPath.updateUser}/${id}`, body);
};
export const adminDeleteUser = async (id: API.DeleteUserProps) => {
  return apiInstance.delete(UserPath.deleteuser.replace(':id', id));
};

// Company
export const getAllCompany = async (params?: API.GetAllCompanyParamsProps) => {
  return apiInstance.get(CompanyPath.All, { params });
};
export const getCompany = async (id: string) => {
  return apiInstance.get(`${CompanyPath.All}/${id}`);
};
export const createCompany = async (body: API.CreateCompanyProps) => {
  return apiInstance.post(CompanyPath.All, body);
};
export const updateCompany = async (
  id: string,
  body: API.UpdateCompanyProps,
) => {
  return apiInstance.patch(`${CompanyPath.All}/${id}`, body);
};
export const deleteCompany = async (id: API.DeleteCompanyProps) => {
  return apiInstance.delete(`${CompanyPath.All}/${id}`);
};

// Device
export const getAllDevices = async (
  workSpaceId?: string,
  params?: API.GetAllDevicesProps,
) => {
  if (workSpaceId)
    return apiInstance.get(DevicePath.All.replace(':workspace', workSpaceId), {
      params,
    });

  return apiInstance.get(DevicePath.All.replace('/:workspace', ''), { params });
};

export const createDevice = async (
  workSpaceId: string,
  body: API.CreateDeviceProps,
) => {
  return apiInstance.post(
    DevicePath.All.replace(':workspace', workSpaceId),
    body,
  );
};
export const updateDevice = async (
  workSpaceId: string,
  deviceId: string,
  body: API.UpdateDeviceProps,
) => {
  return apiInstance.patch(
    `${DevicePath.All.replace(':workspace', workSpaceId)}/${deviceId}`,
    body,
  );
};
export const deleteDevice = async (workSpaceId: string, deviceId: string) => {
  return apiInstance.delete(
    `${DevicePath.All.replace(':workspace', workSpaceId)}/${deviceId}`,
  );
};
export const getDevice = async (workSpaceId: string, deviceId: string) => {
  return apiInstance.get(
    `${DevicePath.All.replace(':workspace', workSpaceId)}/${deviceId}`,
  );
};

// Question and Answer
export const getAllQuestionAndAnswer = async (
  params?: API.getAllQuestionAndAnswerParams,
) => {
  return apiInstance.get(QuestionAndAnswerPath.All, { params });
};
export const getQuestionAndAnswer = async (
  propId: API.getQuestionAndAnswerDetailProps,
) => {
  return apiInstance.get(`${QuestionAndAnswerPath.All}/${propId.id}`);
};
export const createQuestionAndAnswer = async (
  body: API.CreateQuestionAndAnswerProps,
) => {
  return apiInstance.post(QuestionAndAnswerPath.All, body);
};
export const updateQuestionAndAnswer = async (
  id: string,
  body: API.UpdateQuestionAndAnswerProps,
) => {
  return apiInstance.patch(`${QuestionAndAnswerPath.All}/${id}`, body);
};
export const deleteQuestionAndAnswer = async (
  propId: API.DeleteQuestionAndAnswerProps,
) => {
  return apiInstance.delete(`${QuestionAndAnswerPath.All}/${propId}`);
};

// Media
export const getAllMedias = async (
  workspaceId: string,
  params?: API.getAllMediasParams,
) => {
  return apiInstance.get(MediasPath.All.replace(':workspace', workspaceId), {
    params,
  });
};
export const getMedia = async (workspaceId: string, mediaId: string) => {
  return apiInstance.get(
    `${MediasPath.All.replace(':workspace', workspaceId)}/${mediaId}`,
  );
};
export const createMedia = async (
  workspaceId: string,
  body: API.createMediaProps,
) => {
  const formData = new FormData();
  Object.keys(body).forEach((key) => {
    formData.append(key, (body as any)[key]);
  });
  return apiInstance.post(
    MediasPath.All.replace(':workspace', workspaceId),
    formData,
  );
};
export const updateMedia = async (
  workspaceId: string,
  mediaId: string,
  body: API.updateMediaProps,
) => {
  const formData = new FormData();
  Object.keys(body).forEach((key) => {
    formData.append(key, (body as any)[key]);
  });
  return apiInstance.patch(
    `${MediasPath.All.replace(':workspace', workspaceId)}/${mediaId}`,
    formData,
  );
};
export const deleteMedia = async (workspaceId: string, mediaId: string) => {
  return apiInstance.delete(
    `${MediasPath.All.replace(':workspace', workspaceId)}/${mediaId}`,
  );
};

// Tags:
export const getAllMediaTags = async (
  workspaceId: string,
  params?: API.getAllMediaTagsParams,
) => {
  return apiInstance.get(MediaTagPath.All.replace(':workspace', workspaceId), {
    params,
  });
};
export const getMediaTag = async (
  workspaceId: string,
  tagId: API.getMediaTagProps,
) => {
  return apiInstance.get(
    `${MediaTagPath.All.replace(':workspace', workspaceId)}/${tagId}`,
  );
};
export const createMediaTag = async (
  workspaceId: string,
  body: API.createMediaTagProps,
) => {
  return apiInstance.post(
    MediaTagPath.All.replace(':workspace', workspaceId),
    body,
  );
};
export const updateMediaTag = async (
  workspaceId: string,
  tagId: string,
  body: API.updateMediaTagProps,
) => {
  return apiInstance.patch(
    `${MediaTagPath.All.replace(':workspace', workspaceId)}/${tagId}`,
    body,
  );
};
export const deleteMediaTag = async (
  workspaceId: string,
  tagId: API.deleteMediaTagProps,
) => {
  return apiInstance.delete(
    `${MediaTagPath.All.replace(':workspace', workspaceId)}/${tagId}`,
  );
};

// Booking
export const getAllBookings = async (
  workSpaceId: string,
  params?: API.getAllBookingParams,
) => {
  return apiInstance.get(BookingPath.All.replace(':workspaceId', workSpaceId), {
    params,
  });
};
export const getBooking = async (
  workSpaceId: string,
  id: API.getBookingProps,
) => {
  return apiInstance.get(
    `${BookingPath.All.replace(':workspaceId', workSpaceId)}/${id}`,
  );
};
export const validateBooking = async (
  workSpaceId: string,
  body: API.validateBookingProps,
) => {
  return apiInstance.post(
    BookingPath.Validate.replace(':workspaceId', workSpaceId),
    body,
  );
};
export const updateStateBooking = async (
  workSpaceId: string,
  id: string,
  body: {
    state: string;
  },
) => {
  return apiInstance.patch(
    `${BookingPath.All.replace(':workspaceId', workSpaceId)}/${id}`,
    body,
  );
};

// Invoices
export const getAllInvoies = async (
  workSpaceId: string,
  params?: API.getAllInvoicesParams,
) => {
  return apiInstance.get(InvoicePath.All.replace(':workspace', workSpaceId), {
    params,
  });
};
export const getInvoice = async (
  workSpaceId: string,
  id: API.getInvoiceProps,
) => {
  return apiInstance.get(
    `${InvoicePath.All.replace(':workspace', workSpaceId)}/${id}`,
  );
};
export const createInvoices = async (
  workSpaceId: string,
  body: API.createInvoiceProps,
) => {
  return apiInstance.post(
    InvoicePath.All.replace(':workspace', workSpaceId),
    body,
  );
};
export const updateInvoice = async (
  workSpaceId: string,
  id: string,
  note: string,
) => {
  return apiInstance.patch(
    `${InvoicePath.All.replace(':workspace', workSpaceId)}/${id}`,
    note,
  );
};
export const deleteInvoice = async (
  workSpaceId: string,
  id: string,
  params?: { refund: boolean },
) => {
  return apiInstance.delete(
    `${InvoicePath.All.replace(':workspace', workSpaceId)}/${id}`,
    { params },
  );
};

// payment
export const getAllPayments = async (
  workspaceId: string,
  params?: API.GetAllPaymentParams,
) => {
  return apiInstance.get(PaymentPath.All.replace(':workspace', workspaceId), {
    params,
  });
};
export const getPayment = async (workspaceId: string, id: string) => {
  return apiInstance.get(
    `${PaymentPath.All.replace(':workspace', workspaceId)}/${id}`,
  );
};

// Map
export const getMapConfig = async () => {
  return apiInstance.get(CampingMapPath.DEFAULT);
};

export const saveMapConfig = async (file: File, mappingData: MappingCampRP) => {
  const form = new FormData();
  form.append('file', file);
  form.append('mappingData', JSON.stringify(mappingData));
  return apiInstance.put(CampingMapPath.DEFAULT, form);
};

// Chatbot
export const getConfigChatbot = async () => {
  return apiInstance.get(ChatbotPath.DEFAULT);
};
export const addConfigChatbot = async (body: API.AddConfigChatbotType) => {
  return apiInstance.put(ChatbotPath.DEFAULT, body);
};
export const getBackupConfigChatbot = async () => {
  return apiInstance.get(ChatbotPath.BACKUP);
};
export const rollbackConfigChatbot = async () => {
  return apiInstance.patch(ChatbotPath.ROLLBACK, undefined);
};

// Dashboard
export const getDailyAnalytics = async (
  params: API.GetAnalyticsDailyType,
  workSpaceId?: string,
) => {
  if (!workSpaceId) return apiInstance.get(AnalyticsPath.DAILY, { params });
  return apiInstance.get(`${workSpaceId}/${AnalyticsPath.DAILY}`, { params });
};
export const getMonthlyAnalytics = async (
  params: API.GetAnalyticsMonthlyType,
  workSpaceId?: string,
) => {
  if (!workSpaceId) return apiInstance.get(AnalyticsPath.MONTHLY, { params });
  return apiInstance.get(`${workSpaceId}/${AnalyticsPath.MONTHLY}`, { params });
};

// News
export const getAllNews = async (params?: API.getAllNewsProps) => {
  return apiInstance.get(NewsPath.DEFAULT, { params });
};
export const getNews = async (id: string) => {
  return apiInstance.get(`${NewsPath.DEFAULT}/${id}`);
};
export const createNews = async (body: API.createNewsProps) => {
  const formData = new FormData();
  Object.keys(body).forEach((key) => {
    formData.append(key, (body as any)[key]);
  });
  return apiInstance.post(NewsPath.DEFAULT, formData);
};
export const updateNews = async (id: string, body: API.updateNewsProps) => {
  const formData = new FormData();
  Object.keys(body).forEach((key) => {
    formData.append(key, (body as any)[key]);
  });
  return apiInstance.patch(`${NewsPath.DEFAULT}/${id}`, formData);
};
export const deleteNews = async (id: string) => {
  return apiInstance.delete(`${NewsPath.DEFAULT}/${id}`);
};

// RP Tags
export const getAllRPTags = async (params?: API.getAllRPTags) => {
  return apiInstance.get(RPTag.DEFAULT, { params });
};
export const getRPTag = async (id: string) => {
  return apiInstance.get(`${RPTag.DEFAULT}/${id}`);
};
export const createRPTag = async (body: API.createRPTag) => {
  return apiInstance.post(RPTag.DEFAULT, body);
};
export const updateRPTag = async (id: string, body: API.updateRPTag) => {
  return apiInstance.patch(`${RPTag.DEFAULT}/${id}`, body);
};
export const deleteRPTags = async (id: string) => {
  return apiInstance.delete(`${RPTag.DEFAULT}/${id}`);
};
