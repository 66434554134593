import { useTranslation } from 'react-i18next';

const BackToSelectCompany: React.FC = () => {
  const { t } = useTranslation();
  return (
    <a href="/select-workspace" target="_self">
      <button type="button" className="btn btn-user-holder">
        {t('Select-workspace')}
      </button>
    </a>
  );
};
export default BackToSelectCompany;
