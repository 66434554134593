import apiInstance from '@apis/index';
import { IPairCode, IPairedCode } from '@apis/pairing/pairing.interface';
import { AxiosResponse } from 'axios';

export enum PairingPath {
  Get = '/pairing/info/:code',
  Generate = '/pairing/generate',
}

export const generatePairCode = async (): Promise<AxiosResponse<IPairCode>> => {
  return apiInstance.get(PairingPath.Generate);
};

export const getPairResult = async (
  code: string,
): Promise<AxiosResponse<IPairCode | IPairedCode>> => {
  return apiInstance.get(PairingPath.Get.replace(':code', code));
};
