import React, { useState } from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Navigation, { NavigationProps } from '@constants/navigation';
import { useAppSelector } from 'redux/hooks';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const MenuAside: React.FC = () => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const list = Navigation.map((item: NavigationProps) => {
    return {
      label: <span>{t(`Menu-aside.${item.label}`)}</span>,
      key: item.key,
      icon: item.icon,
      children: item.children,
    };
  });

  const role = useAppSelector((state) => state.authorization?.role);

  const items: MenuItem[] = list
    .filter((ele) => {
      if (role && role === 'Admin') return true;
      return ele.key !== 'dashboard' && ele.key !== 'chatbot';
    })
    .map((item: any) => {
      if (!item.children) {
        return getItem(item.label, item.key, item.icon);
      }
      const listChildren = item.children.map((child: any) =>
        getItem(t(`Menu-aside.${child?.label}`), child?.key, child?.icon),
      );
      return getItem(item.label, item.key, item.icon, listChildren);
    });
  const activeMenu = window.location.pathname.split('/')[1];
  // console.log(activeMenu);
  return (
    <aside id="layout-sider">
      <Button
        type="primary"
        onClick={toggleCollapsed}
        style={{ marginBottom: 16, width: '100%' }}
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button>
      <Menu
        // defaultSelectedKeys={['users']}
        // defaultOpenKeys={['users']}
        mode="inline"
        inlineCollapsed={collapsed}
        items={items}
        onClick={(item) => navigate(`/${item.key}`)}
        selectedKeys={[activeMenu]}
      />
    </aside>
  );
};

export default MenuAside;
