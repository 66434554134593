import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../store';

interface AuthorizationData {
  id: string;
  firstName: string;
  lastName: string;
  firstNameHiragana: string;
  lastNameHiragana: string;
  dob: string;
  role: string;
  preferLang: string;
}

export type AuthorizationState = AuthorizationData | null;

const initialState = null as AuthorizationState;

export const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    clearUserData: () => null,
    setUserData: (_, action: PayloadAction<AuthorizationState>) => {
      return action.payload;
    },
  },
});

export const { setUserData, clearUserData } = authorizationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAuthorization = (state: RootState) => state.authorization;

export default authorizationSlice.reducer;
