import { IPairedCode } from '@apis/pairing/pairing.interface';
import PairedInvoice from '@components/PairingClient/components/PairingResult/components/PairedInvoice';
import PairedUser from '@components/PairingClient/components/PairingResult/components/PairedUser';
import { Divider, Space } from 'antd';

type PairingResultProps = {
  result: IPairedCode;
  onClose: () => void;
};

const PairingResult: React.FC<PairingResultProps> = ({ result, onClose }) => {
  return (
    <Space direction="vertical" className="full-width">
      <PairedUser user={result.pairedUser} onClose={onClose} />
      {result.pairedInvoice && (
        <>
          <Divider />
          <PairedInvoice invoice={result.pairedInvoice} onClose={onClose} />
        </>
      )}
    </Space>
  );
};

export default PairingResult;
