import PairingModal from '@components/PairingClient/components/PairingModal';
import { Tooltip } from 'antd';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'redux/hooks';
import { QrcodeOutlined } from '@ant-design/icons';

const PairingClient = () => {
  const { t } = useTranslation('Common');
  const [open, setOpen] = useState(false);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);
  const authorization = useAppSelector((state) => state.authorization);
  if (!authorization) return null;
  return (
    <Tooltip title={t('PairingClient.Button.Tooltip')}>
      {/* <Button onClick={onOpen}>{t('PairingClient.Button.Title')}</Button> */}
      <div className="pair-container">
        <QrcodeOutlined onClick={onOpen} className="pair-icon" />
        <PairingModal open={open} onClose={onClose} />
      </div>
    </Tooltip>
  );
};

export default PairingClient;
